import React from "react"

export default function LinkedInLogo() {
  return (
    <div className="w-6">
      <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2.28308C0 1.02289 1.05925 0 2.365 0H29.635C30.9413 0 32 1.02289 32 2.28308V29.5893C32 30.8499 30.9413 31.872 29.635 31.872H2.365C1.05938 31.872 0 30.85 0 29.5897V2.28271V2.28308Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M9.7245 26.6726V12.3237H4.936V26.6726H9.725H9.7245ZM7.33125 10.365C9.00075 10.365 10.0401 9.26313 10.0401 7.88616C10.0089 6.47781 9.00075 5.40674 7.363 5.40674C5.72412 5.40674 4.65375 6.47781 4.65375 7.88603C4.65375 9.263 5.69275 10.3648 7.29987 10.3648H7.33087L7.33125 10.365ZM12.375 26.6726H17.1631V18.6604C17.1631 18.2321 17.1944 17.8027 17.3209 17.4968C17.6669 16.6396 18.4547 15.7523 19.7779 15.7523C21.5101 15.7523 22.2035 17.068 22.2035 18.9972V26.6726H26.9915V18.4454C26.9915 14.0382 24.6295 11.9873 21.4791 11.9873C18.8962 11.9873 17.7619 13.4252 17.1316 14.4045H17.1635V12.3242H12.3752C12.4377 13.6703 12.3749 26.6731 12.3749 26.6731L12.375 26.6726Z"
          fill="#15161B"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="0"
            y1="0"
            x2="32"
            y2="34.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD18B" />
            <stop offset="0.510417" stopColor="#AC8A55" />
            <stop offset="1" stopColor="#4D320A" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
