import React from "react"

export default function InstagramLogo() {
  return (
    <div className="w-6">
      <svg
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8973 16C11.8973 13.7347 13.7333 11.8987 16 11.8987C18.2667 11.8987 20.104 13.7333 20.104 16C20.104 18.2667 18.2653 20.1027 16 20.1027C13.7347 20.1027 11.8973 18.2653 11.8973 16ZM22.492 15.0853C22.536 15.3853 22.564 15.6893 22.564 16C22.564 19.6267 19.6253 22.564 16.0013 22.564C12.3773 22.564 9.43733 19.6253 9.43733 16C9.43733 15.6893 9.46533 15.3853 9.50933 15.0853C9.55067 14.7907 9.60933 14.5027 9.688 14.2227H8V23.1813C8 23.6333 8.36667 24 8.82133 24H23.18C23.6333 24 24 23.6333 24 23.18V14.2213H22.3133C22.3907 14.5027 22.4493 14.7907 22.492 15.0853V15.0853ZM23.1787 8H21.2467C20.792 8 20.4267 8.36667 20.4267 8.82V10.7533C20.4267 11.2067 20.792 11.5747 21.2467 11.5747H23.1787C23.632 11.5747 24 11.2067 24 10.7533V8.82C24 8.36667 23.6333 8 23.1787 8V8ZM32 6.66667V25.3333C32 29.0147 29.016 32 25.3333 32H6.66667C2.98533 32 0 29.0147 0 25.3333V6.66667C0 2.98533 2.98533 0 6.66667 0H25.3333C29.016 0 32 2.98533 32 6.66667ZM26.6667 7.79467C26.6667 6.436 25.5653 5.33333 24.2053 5.33333H7.79467C6.436 5.33333 5.33333 6.436 5.33333 7.79467V24.204C5.33333 25.564 6.436 26.6667 7.79467 26.6667H24.2067C25.5653 26.6667 26.6667 25.564 26.6667 24.204V7.79467V7.79467Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="2"
            y1="-5.21541e-07"
            x2="29"
            y2="32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD18B" />
            <stop offset="0.5" stopColor="#AC8A55" />
            <stop offset="1" stopColor="#4D320A" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
